import React, { createContext, useContext, useEffect, useState } from 'react';

import Cookies from 'js-cookie';

export type Theme = 'light' | 'dark';

const domain_name = 'cryptomus.com';

const setThemeCookie = (theme: Theme) => {
  if (globalThis.location.host.includes('cryptomus.com')) {
    Cookies.set('theme', theme, {
      domain: domain_name,
      expires: 400,
    });
  } else {
    Cookies.set('theme', theme, {
      expires: 400,
    });
  }
};

const getCookiesTheme = (): string | undefined => Cookies.get('theme') as Theme;

const ThemeContext = createContext<{
  changeTheme: (theme: Theme) => void;
  theme: Theme;
  toggleTheme: () => void;
}>(null!);

export const useThemeContext = () => useContext(ThemeContext);

export default function ThemeProvider(props) {
  const [theme, setTheme] = useState<Theme>('light');

  const changeTheme = (theme): void => {
    setTheme(theme);
    setThemeCookie(theme);
  };

  const toggleTheme = (): void => {
    if (theme === 'dark') {
      changeTheme('light');
    } else {
      changeTheme('dark');
    }
  };

  useEffect(() => {
    const cookieTheme = getCookiesTheme(); //Берем из кук тему

    if (cookieTheme) {
      setTheme(cookieTheme as Theme); //Устанавливаем тему, взятую из кук

      if (theme === 'dark') {
        document.body.classList.add('dark');
        document.body.classList.remove('light');
      } else if (theme === 'light') {
        document.body.classList.add('light');
        document.body.classList.remove('dark');
      }
    } else {
      const isPreferDarkScheme = globalThis.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches; //Проверяем, стоит ли у пользователя черная тема на устройстве

      if (isPreferDarkScheme) {
        setTheme('dark');
        document.body.classList.add('dark');
        document.body.classList.remove('light');
      } else {
        setTheme('light');
        document.body.classList.add('light');
        document.body.classList.remove('dark');
      }
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ changeTheme, theme, toggleTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}

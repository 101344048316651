import React, { useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import IconArrow from 'icons/common/icon-arrow-zendesk.svg';
import IconZendesk from 'icons/common/icon-zendesk.svg';

import styles from './zd-button.module.scss';

const variantsButton = {
  closed: (isActive: boolean) => ({
    opacity: 0,
    y: isActive ? 30 : -30,
  }),
  open: {
    opacity: 1,
    y: 0,
  },
};

export const ZDButton = () => {
  const [isZendeskOpen, setIsZendeskOpen] = useState<boolean>(false);
  const [unreadCount, setUnreadCount] = useState<number>(0);

  const handleClickZD = () => {
    document.querySelector('iframe[data-product="web_widget"]') &&
      setIsZendeskOpen(!isZendeskOpen);
  };

  useEffect(() => {
    if (isZendeskOpen) {
      globalThis.zE('messenger', 'open');
    } else {
      globalThis.zE('messenger', 'close');
    }
  }, [isZendeskOpen]);

  useEffect(() => {
    const handleMessengerOpen = () => {
      setIsZendeskOpen(false);
      globalThis.zE('messenger', 'close');
    };

    globalThis.zE('messenger:on', 'close', handleMessengerOpen);
    globalThis.zE('messenger:on', 'unreadMessages', (value) => {
      setUnreadCount(value ?? 0);
    });

    return () => {
      globalThis.zE('messenger', 'close');
    };
  }, []);

  return (
    <>
      <button
        aria-label='Support button'
        className={styles.zenDesk}
        onClick={handleClickZD}
      >
        {!!unreadCount && (
          <span className={styles.iconUnread}>{unreadCount}</span>
        )}
        <AnimatePresence mode='wait'>
          <motion.span
            key={`${isZendeskOpen}`}
            animate='open'
            className={styles.icon}
            custom={isZendeskOpen}
            exit='closed'
            initial='closed'
            variants={variantsButton}
            transition={{
              duration: 0.15,
            }}
          >
            {isZendeskOpen ? <IconArrow /> : <IconZendesk />}
          </motion.span>
        </AnimatePresence>
      </button>
    </>
  );
};

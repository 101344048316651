import React, { useEffect } from 'react';

import { TGButton } from './tg-button/tg-button';
import { ZDButton } from './zd-button/zd-button';

import { useGetGeo } from 'services/query-hooks/use-get-geo';

import styles from './support-button.module.scss';

const ZENDESK_KEY = '90f413a4-d768-4c77-be38-1741c3567458';

export const SupportButton = () => {
  const geoQuery = useGetGeo();

  const isVisibleTg = geoQuery?.data?.result?.geo === 'RU' || geoQuery?.isError;

  const insertScript = () => {
    const script = document.createElement('script');

    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
    document.body.append(script);
  };

  useEffect(() => {
    const zendeskScript = document.querySelector('#ze-snippet');

    if (!isVisibleTg && !zendeskScript) {
      insertScript();
    }
  }, [isVisibleTg]);

  if (geoQuery?.isLoading) {
    return null;
  }

  if (isVisibleTg) {
    return (
      <div className={styles.supportButton}>
        <TGButton />
      </div>
    );
  }

  if (!globalThis.zE) {
    return null;
  }

  return (
    <div className={styles.supportButton}>
      <ZDButton />
    </div>
  );
};

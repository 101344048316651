import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import BurgerMenu from '../burger-menu/burger-menu';

import { useThemeContext } from 'app/providers/theme-provider';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { Translation, useTranslation } from 'next-i18next';

import { Button } from 'ui/button/button';
import LanguageSelectSecond from 'ui/language-select-second/language-select-second';
import Logo from 'ui/logo/logo';

import { useAuth } from 'services/auth.service';
import { useClickOutside } from 'hooks/use-click-outside';
import { useScrollLock } from 'hooks/use-scroll-lock';
import { getLocaleLink } from 'utils/get-locale-link';
import { CRYPTOMUS_APP_URL } from 'constants/app-url';

import IconBurger from 'icons/common/burger-menu.svg';
import DropdownArrow from 'icons/common/dropdown-arrow.svg';
import IconArrow from 'icons/common/icon-arrow-down-small.svg';
import IconCatalog from 'icons/common/icon-catalog.svg';
import IconCube from 'icons/common/icon-cube.svg';
import IconDollar from 'icons/common/icon-dollar-in-circle.svg';
import IconMassPayout from 'icons/common/icon-mass-payout.svg';
import IconMoon from 'icons/common/icon-moon.svg';
import IconRefresh from 'icons/common/icon-refresh.svg';
import IconRefreshSquared from 'icons/common/icon-refresh-squared.svg';
import IconStaking from 'icons/common/icon-stake.svg';
import IconSun from 'icons/common/icon-sun.svg';
import IconWallet from 'icons/common/icon-wallet.svg';
import IconMerchant from 'icons/common/merchant-2.svg';
import IconProcessing from 'icons/common/precessing-icon.svg';

import styles from './header.module.scss';

interface IService {
  description: string;
  icon: JSX.Element;
  link: string;
  title: string;
}

export interface IServiceList {
  business: IService[];
  personal: IService[];
}

const serviceList: IServiceList = {
  business: [
    {
      description: 'WhiteLabelDescription',
      icon: <IconMerchant />,
      link: '/white_label',
      title: 'White Label Gateway',
    },
    {
      description:
        'All of our sophisticated processing features conveniently compiled and presented for your business',
      icon: <IconProcessing />,
      link: '/processing',
      title: 'Crypto Processing',
    },
    {
      description:
        'Place your products in one of the categories and attract new customers',
      icon: <IconCatalog />,
      link: 'https://directory.cryptomus.com/',
      title: 'Merchants directory',
    },
    {
      description: 'Make bulk payouts',
      icon: <IconMassPayout />,
      link: '/mass-payout',
      title: 'Mass Payout',
    },
  ],
  personal: [
    {
      description:
        'Your crypto deserves the best – keep it secure and enjoy fast transfers',
      icon: <IconWallet />,
      link: '/wallet',
      title: 'Wallet',
    },
    {
      description: 'P2PTradeDescription',
      icon: <IconRefreshSquared />,
      link: 'https://p2p.cryptomus.com',
      title: 'P2P Trade',
    },
    {
      description:
        'Maximize your earnings with our crypto staking and gain from 5% APY',
      icon: <IconStaking />,
      link: '/staking',
      title: 'Staking',
    },
    {
      description:
        'Get the best rates – convert your crypto with real-time accuracy and speed',
      icon: <IconRefresh />,
      link: '/convert',
      title: 'Converter',
    },
    {
      description:
        'Start earning crypto with us today and unlock your potential',
      icon: <IconDollar />,
      link: '/earn',
      title: 'Earn',
    },
    {
      description:
        'Verifying transactions made easy with Cryptomus Blockchain Explorer',
      icon: <IconCube />,
      link: '/explorer',
      title: 'Explorer',
    },
  ],
};

interface IServiceDropdownProps {
  setServicesOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IServiceItem {
  service: IService;
}

const ServiceItem = ({ service }: IServiceItem) => {
  const router = useRouter();
  const { t, i18n } = useTranslation('common');

  const getServiceLink = (link: string) =>
    link.includes('cryptomus')
      ? link.replace('.com', `.com/${i18n.language}`)
      : link;

  return (
    <li className={clsx({ [styles.active]: router.pathname === service.link })}>
      <Link
        className={styles.service_item}
        href={getServiceLink(service.link)}
        lang={i18n.language}
      >
        <div className={styles.icon}>{service.icon}</div>
        <div className={styles.service_info}>
          <p>{t(`header.${service.title}`)}</p>
          <p>{t(`header.${service.description}`)}</p>
        </div>
      </Link>
    </li>
  );
};

const ServiceDropdown = ({ setServicesOpen }: IServiceDropdownProps) => {
  const router = useRouter();
  const { t } = useTranslation(['common']);
  const [personalOpen, setPersonalOpen] = useState<boolean>(false);
  const [businessOpen, setBusinessOpen] = useState<boolean>(false);

  const types = [
    {
      action: setPersonalOpen,
      isOpen: personalOpen,
      serviceList: serviceList.personal,
      title: 'Personal use',
    },
    {
      action: setBusinessOpen,
      isOpen: businessOpen,
      serviceList: serviceList.business,
      title: 'Business use',
    },
  ];

  useEffect(() => {
    setPersonalOpen(false);
    setBusinessOpen(false);
  }, [router]);

  return (
    <ul className={styles.type_list}>
      {types.map((type) => (
        <li key={type.title} className={styles.type_border}>
          <div
            className={clsx(styles.type_item, {
              [styles.active]: type.serviceList.some(
                (item) => item.link === router.pathname
              ),
            })}
            onMouseEnter={() => type.action(true)}
            onMouseLeave={() => type.action(false)}
          >
            <span>{t(`header.${type.title}`)}</span>
            <IconArrow />
          </div>
          {type.isOpen && (
            <div
              className={styles.service_list_container}
              onMouseEnter={() => {
                type.action(true);
                setServicesOpen(true);
              }}
            >
              <ul
                className={styles.service_list}
                onMouseLeave={() => type.action(false)}
              >
                {type.serviceList.map((service) => (
                  <ServiceItem key={service.title} service={service} />
                ))}
              </ul>
            </div>
          )}
        </li>
      ))}
      <div className={styles.dropdown_arrow}>
        <DropdownArrow />
      </div>
    </ul>
  );
};

export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const { isAuth } = useAuth();
  const router = useRouter();
  const { locale } = router;
  const { theme, toggleTheme } = useThemeContext();
  const serviceRef = useRef<HTMLDivElement>(null);

  const [openBurger, setOpenBurger] = useState<boolean>(false);
  const [servicesOpen, setServicesOpen] = useState<boolean>(false);

  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    setServicesOpen(false);
    setOpenBurger(false);
  }, [router]);

  const handleCloseService = () => {
    setServicesOpen(false);
  };

  useEffect(() => {
    if (openBurger) {
      lockScroll();
    } else {
      unlockScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openBurger]);

  useClickOutside(serviceRef, handleCloseService);

  return (
    <Translation>
      {(t) => (
        <header className={styles.container}>
          <div className={styles.header}>
            <Logo />
            <div className={styles.navbar}>
              <div className={styles.navigation}>
                <div ref={serviceRef} className={styles.services}>
                  <div
                    className={clsx(styles.services_title, {
                      [styles.open]: servicesOpen,
                    })}
                    onClick={() => setServicesOpen(!servicesOpen)}
                  >
                    <span>{t('header.Services')}</span>
                    <IconArrow />
                  </div>
                  {servicesOpen && (
                    <div className={styles.type_list_container}>
                      <ServiceDropdown setServicesOpen={setServicesOpen} />
                    </div>
                  )}
                </div>
                <Link
                  href='/fees/trading'
                  className={clsx({
                    [styles.active]: router.pathname.includes('fees'),
                  })}
                >
                  {t('header.Tariffs')}
                </Link>
                <Link
                  href='/blog'
                  className={clsx({
                    [styles.active]: router.pathname === '/blog',
                  })}
                >
                  {t('header.Blog')}
                </Link>
                <Link
                  href={getLocaleLink('https://doc.cryptomus.com/', locale)}
                  rel='noreferrer'
                  target='_blank'
                >
                  API
                </Link>
              </div>
              <div className={styles.settings}>
                <button
                  aria-label='theme-selector'
                  id='theme__button'
                  onClick={toggleTheme}
                >
                  <motion.span
                    key={theme}
                    exit={{ opacity: 0, y: 15 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                    }}
                    initial={{
                      opacity: 0,
                      y: 15,
                    }}
                    transition={{
                      duration: 0.15,
                    }}
                  >
                    {theme === 'dark' ? <IconSun /> : <IconMoon />}
                  </motion.span>
                </button>

                <LanguageSelectSecond />
              </div>
              <div className={styles.buttons}>
                {isAuth ? (
                  <Button
                    white
                    linkProps={{
                      href: getLocaleLink(
                        `${CRYPTOMUS_APP_URL}/overview`,
                        i18n.language
                      ),
                      type: 'external',
                    }}
                  >
                    {t('Go to dashboard')}
                  </Button>
                ) : (
                  <>
                    <div className={styles.header_btn}>
                      <Button
                        fullWidth
                        white
                        appearance='secondary'
                        linkProps={{
                          href: getLocaleLink(
                            `${CRYPTOMUS_APP_URL}/login`,
                            i18n.language
                          ),
                          type: 'external',
                        }}
                      >
                        {t('header.Login')}
                      </Button>
                    </div>
                    <div className={styles.header_btn}>
                      <Button
                        fullWidth
                        white
                        linkProps={{
                          href: getLocaleLink(
                            `${CRYPTOMUS_APP_URL}/signup`,
                            i18n.language
                          ),
                          type: 'external',
                        }}
                      >
                        {t('Sign Up')}
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <button
                aria-label='burger-menu'
                className={styles.burgerWrapper}
                id='burger__button'
                onClick={() => setOpenBurger(true)}
              >
                <IconBurger />
              </button>
            </div>
            <BurgerMenu
              openBurger={openBurger}
              ServiceItem={ServiceItem}
              serviceList={serviceList}
              setOpenBurger={setOpenBurger}
            />
          </div>
        </header>
      )}
    </Translation>
  );
};

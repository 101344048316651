import React, { useCallback, useRef } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import type {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  MouseEvent,
  SetStateAction,
} from 'react';

import IconClear from 'icons/common/close-rounded.svg';
import IconSearch from 'icons/common/search.svg';

import styles from './selector-input-search.module.scss';

const variantsClearIcon = {
  close: { opacity: 0, x: 20 },
  open: { opacity: 1, x: 0 },
};

interface ISelectorInputSearch {
  placeholder: string;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
}

const SelectorInputSearch = ({
  searchValue,
  setSearchValue,
  ...props
}: ISelectorInputSearch): JSX.Element => {
  const searchRef = useRef<HTMLInputElement>(null);

  const focusInput = (
    e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    searchRef.current?.focus();
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      searchRef.current?.focus();
    },
    [setSearchValue]
  );

  const handleClearValue = () => {
    setSearchValue('');
  };

  return (
    <div
      className={styles.input__wrapper}
      role='searchbox'
      tabIndex={0}
      onClick={focusInput}
      onKeyDown={focusInput}
    >
      <div className={styles.icon_search}>
        <IconSearch />
      </div>
      <input
        ref={searchRef}
        className={styles.input}
        value={searchValue}
        onChange={handleChange}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => e.stopPropagation()}
        {...props}
      />
      <div className={styles.icon_clear_wrapper}>
        <AnimatePresence>
          {searchValue.length > 0 && (
            <motion.span
              animate='open'
              className={styles.icon_clear}
              exit='close'
              initial='close'
              transition={{ bounce: false, duration: 0.1 }}
              variants={variantsClearIcon}
            >
              <IconClear onClick={handleClearValue} />
            </motion.span>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default SelectorInputSearch;

import { useSearchParams } from 'next/navigation';

import Cookies from 'js-cookie';

const domain_name = 'cryptomus.com';

const RefCookieProvider = ({ children }: { children: JSX.Element }) => {
  const { get } = useSearchParams();

  const refQuery = get('ref');

  if (refQuery) {
    const date = new Date();

    date.setMinutes(date.getMinutes() + 30);

    if (globalThis.location.host.includes(domain_name)) {
      Cookies.set('ref', refQuery, { domain: domain_name, expires: date });
    } else Cookies.set('ref', refQuery, { expires: date });
  }

  return children;
};

export default RefCookieProvider;

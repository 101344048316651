import React from 'react';
import Link from 'next/link';

import clsx from 'clsx';
import { i18n } from 'next-i18next';

import IconLogo from 'icons/common/logo-cryptomus.svg';

import styles from './logo.module.scss';

const Logo: React.FC<{ className?: string }> = ({ className }) => (
  <Link
    aria-label='Cryptomus main page'
    className={clsx(styles.logo, className)}
    href='/'
    lang={i18n?.language}
  >
    <IconLogo />
  </Link>
);

export default Logo;

import { api } from './api';

export interface IBasicResponseData<T> {
  result: T;
  state?: number;
}

interface IResult {
  geo: string;
  isError?: boolean;
}

export const SettingsService = {
  async getGeoAxios() {
    const geo =
      await api.get<IBasicResponseData<IResult>>('v1/fingerprint/geo');

    return geo.data;
  },
};

import React from 'react';
import localFont from 'next/font/local';

import type { ReactNode } from 'react';

const Golos = localFont({
  src: [
    {
      path: '../../../../public/fonts/Golos-Text/Golos-Text_Regular.woff',
      style: 'normal',
      weight: '400',
    },
    {
      path: '../../../../public/fonts/Golos-Text/Golos-Text_Black.woff',
      style: 'normal',
      weight: '900',
    },
    {
      path: '../../../../public/fonts/Golos-Text/Golos-Text_Bold.woff',
      style: 'normal',
      weight: '700',
    },
    {
      path: '../../../../public/fonts/Golos-Text/Golos-Text_DemiBold.woff',
      style: 'normal',
      weight: '600',
    },
    {
      path: '../../../../public/fonts/Golos-Text/Golos-Text_Medium.woff',
      style: 'normal',
      weight: '500',
    },
    {
      path: '../../../../public/fonts/Golos-Text/Golos-Text_Regular.woff2',
      style: 'normal',
      weight: '400',
    },
    {
      path: '../../../../public/fonts/Golos-Text/Golos-Text_Black.woff2',
      style: 'normal',
      weight: '900',
    },
    {
      path: '../../../../public/fonts/Golos-Text/Golos-Text_Bold.woff2',
      style: 'normal',
      weight: '700',
    },
    {
      path: '../../../../public/fonts/Golos-Text/Golos-Text_DemiBold.woff2',
      style: 'normal',
      weight: '600',
    },
    {
      path: '../../../../public/fonts/Golos-Text/Golos-Text_Medium.woff2',
      style: 'normal',
      weight: '500',
    },
  ],
});

const FontsProvider: React.FC<{ children: ReactNode }> = ({ children }) => (
  <div className={Golos.className}>{children}</div>
);

export default FontsProvider;

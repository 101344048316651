import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Divider } from '@mui/material';
import clsx from 'clsx';
import { i18n, Translation } from 'next-i18next';

import CertikButton from 'components/footer/certik-button/certik-button';
import AppStoreBtn from 'ui/app-store-btn/app-store-btn';
import { ButtonUp } from 'ui/button-up/button-up';
import CookieModal from 'ui/cookie-modal/cookie-modal';
import Logo from 'ui/logo/logo';
import { SupportButton } from 'ui/support-button/support-button';

import { FOOTER_LINKS, FOOTER_SOCIAL_LINKS } from 'types/footer.data';

import IconLogo from 'icons/footer/footer-logo.svg';

import styles from './footer.module.scss';

export const Footer: React.FC = () => {
  const isLangLink = (link: string): boolean =>
    !['https', 'mailto', 'tg'].some((str) => link.includes(str)) &&
    i18n?.language !== 'en';

  const router = useRouter();

  const { locale, pathname } = router;

  return (
    <>
      {pathname !== '/unsubscribe' && (
        <>
          <Translation>
            {(t) => (
              <footer
                className={clsx(styles.container, styles.footer_container)}
              >
                <div className={styles.footer}>
                  <div className={styles.info}>
                    <div className={styles.logo}>
                      <div className={styles.main}>
                        <Logo className={styles.logoWrapper} />
                        <p>
                          {t(
                            'footer.Cryptomus helps you receive payments from anyone in the world'
                          )}
                        </p>
                        <a
                          aria-label='Trastpilot review'
                          className={styles.trastpilot}
                          href='https://www.trustpilot.com/review/cryptomus.com'
                          target='_blank'
                        >
                          <IconLogo />
                        </a>
                        <AppStoreBtn
                          className={styles.appstore}
                          locale={locale ?? 'en'}
                          theme='light'
                        />
                        <CertikButton className={styles.certikWrapper} />
                      </div>
                    </div>
                    <div className={styles.links}>
                      {FOOTER_LINKS.map((item, i) => (
                        <div
                          key={item.title}
                          className={styles[`area-${i + 1}`]}
                        >
                          <h3>{t(`footer.${item.title}`)}</h3>
                          {item.items.map((link) => (
                            <a
                              key={link.name}
                              href={
                                isLangLink(link.link)
                                  ? `/${i18n?.language}${link.link}`
                                  : link.link
                              }
                              target={
                                link.link.includes('https') ? '_blank' : '_self'
                              }
                            >
                              {t(`footer.${link.name}`)}
                            </a>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                  <Divider style={{ borderColor: '#242424', width: '100vw' }} />
                  <div className={styles.social} dir='ltr'>
                    <div className={styles.links}>
                      {FOOTER_SOCIAL_LINKS.map((link) => (
                        <a
                          key={link.label}
                          aria-label={link.label}
                          className={styles.small_icon}
                          href={link.href}
                          target='_blank'
                        >
                          {link.icon}
                        </a>
                      ))}
                    </div>
                    <div className={styles.rights}>
                      <Link href='/aml'>AML</Link>
                      <Link href='/privacy'>Privacy policy</Link>
                      <Link href='/tos'>Terms of use</Link>
                    </div>
                  </div>
                  <Divider style={{ borderColor: '#242424', width: '100vw' }} />
                  <div className={styles.juridical} dir='ltr'>
                    <p>
                      XELTOX ENTERPRISES LTD. is registered as a Money service
                      business (MSB) with the Financial Transactions and Reports
                      Analysis Centre of Canada (FINTRAC) under registration
                      number M22649585
                    </p>
                    <p>Ⓒ 2024 Cryptomus. All Rights Reserved</p>
                  </div>
                </div>
              </footer>
            )}
          </Translation>
          <CookieModal />
          <ButtonUp />

          <SupportButton />
        </>
      )}
    </>
  );
};

import { api } from './api';

import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';

interface IUseAuthData {
  result: {
    expired_at: string;
    status: boolean;
    token: string;
  };
  state: number;
}

export const useAuth = () => {
  const token: string | undefined = Cookies.get('session_id');

  const { data, isSuccess, status } = useQuery({
    enabled: !!token,
    queryFn: () =>
      api.post<IUseAuthData>('v1/login/verify-token', {
        token,
      }),
    queryKey: ['verifyToken'],
  });

  return { isAuth: isSuccess && data?.data.result.status, status };
};

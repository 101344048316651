import { useQuery } from '@tanstack/react-query';

import { SettingsService } from 'services/setting.service';

export const keyGeo = 'getGeo';

export const useGetGeo = () =>
  useQuery({
    queryFn: () => SettingsService.getGeoAxios(),
    queryKey: [keyGeo],
  });

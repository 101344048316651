const splitUrl = (url: string) => {
  // Получаем часть пути из URL
  const path = url.split('/').slice(3).join('/'); // Учитываем только часть после домена

  const beforePath = path?.length ? url.split(path)[0] : url;

  const afterPath = path?.length
    ? url.slice(Math.max(0, url.lastIndexOf(path)))
    : '';

  return { after: afterPath, before: beforePath };
};

export const getLocaleLink = (link: string, curLocale = 'en') => {
  const localeSubstr = curLocale === 'en' ? '' : curLocale;
  const slash = curLocale === 'en' ? '' : '/';
  const { before, after } = splitUrl(link);

  return `${before}${localeSubstr}${slash}${after}`;
};

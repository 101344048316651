import React, { useEffect, useRef, useState } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FontsProvider from 'app/providers/fonts-provider/fonts-provider';
import RefCookieProvider from 'app/providers/ref-cookie-provider';
import ThemeProvider from 'app/providers/theme-provider';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import type { NextPage } from 'next';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { SnackbarProvider } from 'notistack';
import type { ReactElement, ReactNode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { Footer } from 'components/footer/footer';
import { Header } from 'components/header/header';

import { api } from 'services/api';
import { changeLanguage } from 'utils/change-language';
import { LOCALES } from 'constants/locales';

import 'app/styles/globals.scss';
import 'app/styles/variables.scss';

declare global {
  interface Window {
    zE: (
      type: 'messenger' | 'messenger:on' | 'messenger:set',
      action: 'show' | 'hide' | 'open' | 'close' | 'locale' | 'unreadMessages',
      callback?: (value: number) => void
    ) => void;
  }
}

type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

api.interceptors.request.use(
  (config) => {
    config.headers['Language'] = i18next?.language || 'en';

    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // RTL TRANSLATION SUPPORT
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n?.dir?.();
  }, [i18n]);

  const getLayout = Component.getLayout ?? ((page) => page);
  const notistackRef = useRef<SnackbarProvider>(null);
  const router = useRouter();

  const [commentsCountList, setCommentsCountList] =
    useState<{ count: number; post_id: string }[]>();

  useEffect(() => {
    fetch('https://api-app.cryptomus.com/v1/blog/count-comments')
      .then((res) => res.json())
      .then((res) => setCommentsCountList(res.result));
  }, []);

  useEffect(() => {
    const cookieLanguage = Cookies.get('i18next');

    if (
      router?.locale &&
      LOCALES.some((lang) => globalThis?.location?.href.includes(lang))
    )
      changeLanguage(router?.locale);
    else if (cookieLanguage && cookieLanguage !== router.locale) {
      router.push(router.asPath, router.asPath, { locale: cookieLanguage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDismiss = (key) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };

  const utmTags = [
    'utm_source',
    'utm_content',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'ref',
    'click_id',
    'cpa',
    'user_id',
    'client_id',
  ];

  useEffect(() => {
    utmTags?.map((tag) => {
      if (router?.query?.[tag]) {
        Cookies.set(tag, router?.query?.[tag] as string, {
          domain: 'cryptomus.com',
          expires: 7,
        });
      } else {
        if (Cookies.get(tag) === 'undefined') {
          Cookies.remove(tag, { domain: 'cryptomus.com' });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.query]);

  return (
    <ThemeProvider>
      <GoogleReCaptchaProvider
        reCaptchaKey={`${process.env.NEXT_PUBLIC_NOT_SECRET_CODE}`}
      >
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            ref={notistackRef}
            autoHideDuration={3000}
            maxSnack={3}
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)}>
                <CloseIcon />
              </IconButton>
            )}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
          >
            <RefCookieProvider>
              <FontsProvider>
                <Header />
                {getLayout(
                  <Component
                    {...pageProps}
                    commentsCountList={commentsCountList}
                  />
                )}
                <Footer />
              </FontsProvider>
            </RefCookieProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  );
}

export default appWithTranslation(MyApp);

import React, { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import SelectorInputSearch from './selector-input-search/selector-input-search';
import { BootstrapInput, dropdownMenuProps } from './styled-select.data';

import styled from '@emotion/styled';
import type {
  InputBasePropsColorOverrides,
  InputBasePropsSizeOverrides,
} from '@mui/material';
import { Select } from '@mui/material';
import type { OverridableStringUnion } from '@mui/types';
import { useTranslation } from 'react-i18next';

import { useScrollLock } from 'hooks/use-scroll-lock';
import { changeLanguage } from 'utils/change-language';

import IconCheck from 'icons/common/icon-check-2.svg';
import IconClose from 'icons/common/icon-close.svg';
import IconGlobe from 'icons/common/icon-glob-2.svg';

import styles from './styled-select.module.scss';

const capitalize = (str: string): string => {
  if (!str) {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

const availableLanguages: {
  [key: string]: { order: number; value: string };
} = {
  ar: { order: 12, value: 'العربية' },
  de: { order: 3, value: 'Deutsche' },
  en: { order: 1, value: 'English' },
  es: { order: 2, value: 'Español' },
  fa: { order: 16, value: 'فارسی' },
  fr: { order: 4, value: 'Français' },
  ja: { order: 14, value: '日本語' },
  kk: { order: 10, value: 'Қазақша' },
  ko: { order: 13, value: '한국어' },
  pa: { order: 17, value: 'ਪੰਜਾਬੀ' },
  pl: { order: 11, value: 'Polski' },
  pt: { order: 15, value: 'Português' },
  ru: { order: 6, value: 'Русский' },
  tr: { order: 5, value: 'Türkçe' },
  uk: { order: 7, value: 'Українська' },
  uz: { order: 9, value: "O'zbekcha" },
  zh: { order: 8, value: '中文' },
};

const getLanguageFullName = (code: string): string => {
  const languageName = availableLanguages[code].value;

  return capitalize(languageName || '');
};

const Image = styled.img`
  width: 24px;
  height: 24px;
`;

interface ILanguageSelect {
  backgroundColor?: string;
  color?: OverridableStringUnion<
    'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning',
    InputBasePropsColorOverrides
  >;
  size?: OverridableStringUnion<
    'small' | 'medium',
    InputBasePropsSizeOverrides
  >;
  type?: 'widget' | 'auth';
}

const LanguageSelectSecond = ({ ...props }: ILanguageSelect) => {
  const { t } = useTranslation('common');
  const [value, setValue] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { lockScroll, unlockScroll } = useScrollLock();

  const router = useRouter();

  const languages = useMemo(
    () =>
      Object.keys(availableLanguages).sort(
        (a, b) => availableLanguages[a].order - availableLanguages[b].order
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router]
  );

  const filteredOptionsList =
    searchValue === ''
      ? languages
      : languages.filter((elem) =>
          getLanguageFullName(elem)
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        );

  const handleClear = (): void => {
    setValue('en');
  };

  const change = (lng: string) => {
    setValue(lng);
    changeLanguage(lng);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [isOpen, lockScroll, unlockScroll]);

  return (
    <div className={styles.lng_wrapper}>
      <button
        aria-label='language-selector'
        id='language__button'
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <IconGlobe />
      </button>
      <Select
        displayEmpty
        input={<BootstrapInput className={styles.inputWrapper} />}
        MenuProps={dropdownMenuProps}
        open={isOpen}
        value={value}
        startAdornment={
          value?.length ? (
            <button
              aria-label='Clear'
              className={styles.clear__button}
              onClick={() => handleClear()}
            >
              <div className={styles.clear__button_iconWrapper}>
                <IconClose className={styles.icon_close} />
              </div>
            </button>
          ) : null
        }
        onClose={() => {
          setSearchValue('');
          setIsOpen(false);
        }}
        {...props}
      >
        <div className={styles.inputSearchWrapper}>
          <SelectorInputSearch
            key='search'
            placeholder={t('header.Search')}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </div>
        {/* <div className={styles.selectList__bg}></div> */}
        <div className={styles.selectList}>
          {filteredOptionsList.length > 0 ? (
            filteredOptionsList.map((lang: string) => (
              <div
                key={lang}
                className={styles.element}
                onClick={() => change(lang)}
              >
                <Link
                  className={styles.link__item}
                  href={router.asPath}
                  locale={lang}
                >
                  <div className={styles.link__lang}>
                    <div className={styles.link__flag}>
                      <Image
                        alt={`flag-${lang}`}
                        height={24}
                        src={`/icons/flags/flag-${lang}.svg`}
                        width={24}
                      />
                    </div>
                    {getLanguageFullName(lang)}
                  </div>
                  <div>{lang === router.locale && <IconCheck />}</div>
                </Link>
              </div>
            ))
          ) : (
            <p className={styles.notFoundText}>{t('header.Not found')}</p>
          )}
        </div>
      </Select>
    </div>
  );
};

export default LanguageSelectSecond;

import styled from '@emotion/styled';
import { InputBase } from '@mui/material';

export const BootstrapInput = styled(InputBase)({
  '& .Mui-disabled': {
    WebkitTextFillColor: 'var(--primary) !important',
    backgroundColor: 'var(--backgroundTertiary)',
    border: 'none',
    opacity: '0.5 !important',
  },
  '& .MuiInputBase-input': {
    '&:focus': {
      borderRadius: 12,
    },

    borderRadius: 12,

    fontFamily: 'Golos',

    letterSpacing: '-0.12px',

    padding: '12px 16px',

    position: 'relative',
  },
  '& .MuiSelect-icon': {
    right: '15px',
  },
  borderRadius: '12px !important',
  height: 0,
  marginTop: '16px',
  opacity: 0,
  position: 'absolute',
  right: '165px',
  visibility: 'hidden',
  width: 0,
});

export const dropdownMenuProps = {
  // disablePortal: true,
  // anchorEl: this,
  PaperProps: {
    sx: {
      '& .Mui-selected': {
        backgroundColor: 'var(--backgroundSecondary) !important',
        color: 'var(--primary)',
      },
      '& .MuiCheckbox-root': {
        padding: '0 !important',
      },
      '& .MuiInputBase-root': {
        display: 'none',
      },
      '& .MuiMenu-list': {
        padding: '0',
      },

      // maxWidth: 240,
      '& .MuiMenuItem-root': {
        fontFamily: 'Golos',
        letterSpacing: '-0.12px',
        padding: '12px 16px',
      },

      '&::-webkit-scrollbar': {
        width: 12,
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundClip: 'content-box',
        backgroundColor: '#7A7A7A',
        border: '3px solid transparent',
        borderRadius: '100px',
      },

      '&::-webkit-scrollbar-track': {
        borderColor: 'rgba(0, 0, 0, 0.10)',
        borderRadius: '0 12px 12px 0',
        borderStyle: 'solid',
        borderWidth: '0px 1px',
      },

      backgroundColor: 'var(--secondary)',

      border: '1px solid var(--border_lang)',

      borderRadius: '12px',

      boxShadow: '-10px 0px 80px 0px rgba(0, 0, 0, 0.10)',

      color: 'var(--primary)',

      left: -32,
      maxHeight: 286,
      maxWidth: 282,
      overflowY: 'hidden',
      padding: '0',
    },
  },

  disableScrollLock: true,
};

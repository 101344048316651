import React from 'react';

import IconDiscord from 'icons/footer/footer-discord.svg';
import IconFacebook from 'icons/footer/footer-facebook.svg';
import IconInstagram from 'icons/footer/footer-instagram.svg';
import IconLinkedin from 'icons/footer/footer-linkedin.svg';
import IconLReddit from 'icons/footer/footer-reddit.svg';
import IconTelegram from 'icons/footer/footer-telegram.svg';
import IconTwitter from 'icons/footer/footer-twitter.svg';
import IconYoutube from 'icons/footer/footer-youtube.svg';

export interface IFooterColumnItem {
  link: string;
  name: string;
}

export interface IFooterItem {
  items: IFooterColumnItem[];
  title: string;
}

export interface ISocialLinks {
  href: string;
  icon: JSX.Element;
  label: string;
}

export const FOOTER_SOCIAL_LINKS: ISocialLinks[] = [
  {
    href: 'tg://resolve?domain=cryptomus',
    icon: <IconTelegram />,
    label: 'Telegram',
  },
  { href: 'https://x.com/cryptomus', icon: <IconTwitter />, label: 'Twitter' },
  {
    href: 'https://www.reddit.com/user/Cryptomuscom/',
    icon: <IconLReddit />,
    label: 'Reddit',
  },
  {
    href: 'https://www.youtube.com/@cryptomus',
    icon: <IconYoutube />,
    label: 'Youtube',
  },
  {
    href: 'https://www.linkedin.com/company/93083371',
    icon: <IconLinkedin />,
    label: 'Linkedin',
  },
  {
    href: 'https://www.facebook.com/cryptomus',
    icon: <IconFacebook />,
    label: 'Facebook',
  },
  {
    href: 'https://instagram.com/cryptomus',
    icon: <IconInstagram />,
    label: 'Instagram',
  },
  {
    href: 'https://discord.gg/9vHK6R9HkQ',
    icon: <IconDiscord />,
    label: 'Discord',
  },
];

export const FOOTER_LINKS: IFooterItem[] = [
  {
    items: [
      {
        link: '/',
        name: 'Home',
      },
      {
        link: '/fees/trading',
        name: 'Tariffs',
      },
      {
        link: '/roadmap',
        name: 'Roadmap',
      },
      {
        link: '/brand-guideline',
        name: 'Brand guideline',
      },
      {
        link: '/blog',
        name: 'Blog',
      },
      {
        link: '/faq/getting-started',
        name: 'FAQ',
      },
      {
        link: '/contacts',
        name: 'Contacts',
      },
    ],
    title: 'Company',
  },
  {
    items: [
      {
        link: '/wallet',
        name: 'Wallet',
      },
      {
        link: 'https://p2p.cryptomus.com',
        name: 'P2P Exchange',
      },
      {
        link: '/staking',
        name: 'Staking',
      },
      {
        link: '/convert',
        name: 'Converter',
      },
      {
        link: '/earn',
        name: 'Earn',
      },
      {
        link: '/explorer',
        name: 'Explorer',
      },
    ],
    title: 'Personal use',
  },
  {
    items: [
      {
        link: '/white_label',
        name: 'White Label',
      },
      {
        link: '/processing',
        name: 'Crypto Processing',
      },
      {
        link: '/plugins',
        name: 'E-commerce plugins',
      },
      {
        link: 'https://doc.cryptomus.com/',
        name: 'API',
      },
    ],
    title: 'Business use',
  },
  {
    items: [
      {
        link: 'tg://resolve?domain=cryptomus_support_bot',
        name: 'Telegram',
      },
      {
        link: 'mailto:support@cryptomus.com',
        name: 'Email',
      },
    ],
    title: 'Support',
  },
];

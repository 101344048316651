import React from 'react';

import IconTG from 'icons/common/icon-tg-button-2.svg';

import styles from './tg-button.module.scss';

export const TGButton = () => (
  <a
    className={styles.tgButton}
    href='tg://resolve?domain=cryptomus_support_bot'
    rel='noreferrer'
    target='_blank'
  >
    <IconTG />
  </a>
);

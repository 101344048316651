import Link from 'next/link';

import clsx from 'clsx';
import type { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import IconLoading from 'icons/common/icon-loading.svg';

import styles from './button.module.scss';

export interface ILinkProps {
  href: string;
  type: 'internal' | 'external' | 'download';
  anchor?: string;
  locale?: string;
  scroll?: boolean;
}

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  adaptivePadding?: boolean;
  appearance?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'gray'
    | 'outline14'
    | 'white';
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  linkProps?: ILinkProps;
  loading?: boolean;
  size?: 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxl';
  white?: boolean;
  width?: string;
  withoutBorder?: boolean;
}

export const Button: FC<IButtonProps> = ({
  children,
  appearance = 'primary',
  white,
  withoutBorder,
  loading,
  disabled,
  size = 's',
  fullWidth,
  width,
  linkProps,
  className,
  adaptivePadding,
  ...props
}) => {
  const button = (className?: string) => (
    <button
      {...props}
      disabled={loading || disabled}
      style={{ width }}
      className={clsx(styles.button, styles[size], className, {
        [styles.primary]: appearance === 'primary',
        [styles.secondary]: appearance === 'secondary',
        [styles.gray]: appearance === 'gray',
        [styles.outline14]: appearance === 'outline14',
        [styles.tertiary]: appearance === 'tertiary',
        [styles.white]: white || appearance === 'white',
        [styles.without_border]: withoutBorder,
        [styles.full_width]: fullWidth,
        [styles.disabled]: loading || disabled,
        [styles.adaptivePadding]: adaptivePadding,
      })}
    >
      {loading ? (
        <div
          className={clsx({
            [styles.loading]: loading,
          })}
        >
          <IconLoading />
        </div>
      ) : (
        children
      )}
    </button>
  );

  if (linkProps?.type === 'external') {
    return (
      <a className={className} href={linkProps.href} target='_blank'>
        {button()}
      </a>
    );
  }

  if (linkProps?.type === 'internal') {
    return (
      <Link
        as={linkProps.anchor ? linkProps.href : undefined}
        className={className}
        locale={linkProps.locale}
        scroll={linkProps.scroll ?? true}
        href={
          linkProps?.anchor
            ? { pathname: linkProps.href, query: { anchor: linkProps.anchor } }
            : linkProps.href
        }
      >
        {button()}
      </Link>
    );
  }

  if (linkProps?.type === 'download') {
    return (
      <a download className={className} href={linkProps.href} target='_blank'>
        {button()}
      </a>
    );
  }

  return button(className);
};

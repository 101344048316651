import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { Translation, useTranslation } from 'next-i18next';

import type { IServiceItem, IServiceList } from 'components/header/header';
import { Button } from 'ui/button/button';
import Logo from 'ui/logo/logo';

import { useAuth } from 'services/auth.service';
import { getLocaleLink } from 'utils/get-locale-link';
import { CRYPTOMUS_APP_URL } from 'constants/app-url';

import IconArrow from 'icons/common/accordion-arrow-down.svg';
import IconClose from 'icons/common/burger-icon-close.svg';

import styles from './burger-menu.module.scss';

const variants = {
  closed: {
    height: 0,
  },
  open: {
    height: 'auto',
  },
};

interface IBurgerMenuProps {
  ServiceItem: ({ service }: IServiceItem) => JSX.Element;
  openBurger: boolean;
  serviceList: IServiceList;
  setOpenBurger: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IServiceDropdownProps {
  ServiceItem: ({ service }: IServiceItem) => JSX.Element;
  isOpen: boolean;
  serviceList: IServiceList;
}

const ServiceDropdown: React.FC<IServiceDropdownProps> = ({
  serviceList,
  ServiceItem,
  isOpen,
}) => {
  const { t } = useTranslation(['common']);
  const [personalOpen, setPersonalOpen] = useState<boolean>(false);
  const [businessOpen, setBusinessOpen] = useState<boolean>(false);

  const types = [
    {
      action: setPersonalOpen,
      isOpen: personalOpen,
      serviceList: serviceList.personal,
      title: 'Personal use',
    },
    {
      action: setBusinessOpen,
      isOpen: businessOpen,
      serviceList: serviceList.business,
      title: 'Business use',
    },
  ];

  return (
    <AnimatePresence mode='wait'>
      <motion.div
        animate={isOpen ? 'open' : 'closed'}
        className={styles.types_container}
        exit='closed'
        initial='closed'
        transition={{ duration: 0.3 }}
        variants={variants}
      >
        {types.map((type) => (
          <div key={type.title} className={styles.type}>
            <div
              className={styles.type_title}
              onClick={() => type.action((prev) => !prev)}
            >
              <p>{t(`header.${type.title}`)}</p>
              <div
                className={clsx(styles.icon, { [styles.open]: type.isOpen })}
              >
                <IconArrow />
              </div>
            </div>
            <AnimatePresence mode='wait'>
              <motion.div
                animate={type.isOpen ? 'open' : 'closed'}
                className={styles.types_container}
                exit='closed'
                initial='closed'
                transition={{ duration: 0.3 }}
                variants={variants}
              >
                <ul>
                  {type.serviceList.map((service) => (
                    <ServiceItem key={service.title} service={service} />
                  ))}
                </ul>
              </motion.div>
            </AnimatePresence>
          </div>
        ))}
      </motion.div>
    </AnimatePresence>
  );
};

const BurgerMenu = ({
  setOpenBurger,
  openBurger,
  serviceList,
  ServiceItem,
}: IBurgerMenuProps): JSX.Element => {
  const { isAuth } = useAuth();
  const [servicesOpen, setServicesOpen] = useState<boolean>(false);

  const router = useRouter();
  const { locale } = router;

  return (
    <Translation>
      {(t) => (
        <div
          className={clsx(styles.burger__wrapper, {
            [styles.active]: openBurger,
          })}
        >
          <div className={clsx(styles.burger__content)}>
            <div className={styles.header}>
              <Logo />
              <IconClose
                className={styles.icon_close}
                onClick={() => setOpenBurger(false)}
              />
            </div>
            <div className={clsx(styles.burger__menu)}>
              <div
                className={styles.menu_item}
                onClick={() => setServicesOpen((prev) => !prev)}
              >
                <p>{t('header.Services')}</p>
                <div
                  className={clsx(styles.icon, { [styles.open]: servicesOpen })}
                >
                  <IconArrow />
                </div>
              </div>
              <ServiceDropdown
                isOpen={servicesOpen}
                ServiceItem={ServiceItem}
                serviceList={serviceList}
              />
              <Link href='/fees/trading' onClick={() => setOpenBurger(false)}>
                {t('header.Tariffs')}
              </Link>
              <Link href='/blog' onClick={() => setOpenBurger(false)}>
                {t('header.Blog')}
              </Link>
              <Link
                href={getLocaleLink('https://doc.cryptomus.com/', locale)}
                rel='noreferrer'
                target='_blank'
              >
                API
              </Link>
            </div>
          </div>

          <div className={styles.burger__buttons}>
            {isAuth ? (
              <Button
                fullWidth
                appearance='primary'
                linkProps={{
                  href: getLocaleLink(`${CRYPTOMUS_APP_URL}/overview`, locale),
                  type: 'external',
                }}
              >
                {t('Go to dashboard')}
              </Button>
            ) : (
              <>
                <Button
                  fullWidth
                  appearance='secondary'
                  linkProps={{
                    href: getLocaleLink(`${CRYPTOMUS_APP_URL}/login`, locale),
                    type: 'external',
                  }}
                >
                  {t('header.Login')}
                </Button>
                <Button
                  fullWidth
                  appearance='primary'
                  linkProps={{
                    href: getLocaleLink(`${CRYPTOMUS_APP_URL}/signup`, locale),
                    type: 'external',
                  }}
                >
                  {t('Sign Up')}
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </Translation>
  );
};

export default BurgerMenu;
